import React from 'react'
import { Outlet } from "react-router-dom";
import Header from './Header';
import Footer from './Footer';
// import '../Css/index.css'

export default function Default() {
      return (
            <div >
                  <Header />
                  <Outlet />
                  <Footer />
            </div>
      )
}
